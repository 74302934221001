import React, { FunctionComponent, ReactElement } from 'react';
import { SEO, ExperienceCards } from '../components';
import { MainLayout } from '../layouts';

const ExperiencePage: FunctionComponent = (): ReactElement => {
    return (
        <MainLayout>
            <SEO title="PCH | Experience"/>
            <ExperienceCards/>
        </MainLayout>
    );
}

export default ExperiencePage;
